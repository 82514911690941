import React, { useEffect } from "react"

function Products({ params }) {
  useEffect(() => {
    window.location.href = `/shop/${params.productType}/${params.productName}`
  }, [])
  return null
}

export default Products
